export const ID = {
    TOGGLE_SIDE_MENU:"sideMenuToggle",
    MENU_LIST:"ulMenu",
    MENU_ANCHOR:"menuAnchor",
    LAST_VISITED:"skjemaAdminLastVisited"
};

export const CLASS = {
    ACTIVE_TOGGLE:"skjema-admin__side-menu__toggle-button--active",
    ACTIVE_LINK:"skjema-admin__side-menu__anchor--active",
    BUTTON_COLLAPSED:"skjema-admin__side-menu__expand-button--collapsed"
};