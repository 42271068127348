const questionMarkSvg = `
<svg fill="#4d4d4d" height="19px" width="19px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340"><path d="M170 0C76.109 0 0 76.112 0 170s76.109 170 170 170c93.89 0 170-76.112 170-170S263.89 0 170 0zm-.367 281.611c-12.574 0-22.803-10.23-22.803-22.804 0-12.573 10.229-22.803 22.803-22.803 12.575 0 22.806 10.229 22.806 22.803 0 12.574-10.231 22.804-22.806 22.804zm35.724-111.952c-9.856 9.221-16.367 15.312-16.367 30.062v10.911a5 5 0 01-5 5h-27.122a5 5 0 01-5-5v-10.911c0-30.293 15.744-45.076 27.239-55.87 9.785-9.179 16.248-15.242 16.248-29.778 0-4.591 0-18.562-24.925-18.562-23.37 0-24.306 12.883-24.705 18.384l-.813 11.186a4.998 4.998 0 01-1.771 3.464 4.99 4.99 0 01-3.715 1.148l-27.051-2.707a5 5 0 01-4.488-5.351l.799-10.613c2.41-31.974 26.749-52.634 62.006-52.634 18.438 0 33.952 5.176 44.865 14.968 11.066 9.931 16.918 24.102 16.918 40.98.002 29.929-15.673 44.606-27.118 55.323z"/></svg>
`;

export default function(tableSelector:string){
    const tables:Array<HTMLTableElement> = Array.from(document.querySelectorAll(tableSelector));

    tables.forEach(table => {
        const rows:Array<HTMLTableRowElement> = Array.from(table.rows);
        rows.forEach(row => {
            const lastTd = row.children[row.children.length-1];

            if(lastTd.innerHTML.trim().length > 0){
                const icon = document.createElement("div");
                const text = document.createElement("div");
                const flex = document.createElement("div");

                flex.className="d-flex";

                icon.innerHTML = questionMarkSvg;
                icon.classList.add("skjema-admin__cursor-pointer", "mr-3");

                text.classList.add("d-none");
                text.innerHTML = lastTd.innerHTML;
                lastTd.innerHTML = "";

                flex.appendChild(icon);
                flex.appendChild(text);
                lastTd.appendChild(flex);

                icon.addEventListener("click", () => {
                    text.classList.toggle("d-none");
                });
            }
        })
    });
}