// @ts-nocheck
export default function(toggleSelector:string,panelSelector:string){
    if(toggleSelector && panelSelector){
        const arrOfToggles = Array.from(document.querySelectorAll(toggleSelector));
        const arrOfPanels = Array.from(document.querySelectorAll(panelSelector));
        const arrOfPanelIds = arrOfToggles
            .map(toggleElm => toggleElm.getAttribute("data-tab-id"));

        const addToggleListener = (elm:HTMLElement) => {
            elm.addEventListener("click", (event) => {
                const currentToggle = (<HTMLElement>event.currentTarget);
                const tabId = currentToggle.getAttribute("data-tab-id");

                arrOfPanelIds.forEach((id) => {
                    if (id === tabId){
                        const selectedTab = document.querySelector(`[data-tab-id='${id}'][data-jscomponent='tabPanel']`);

                        selectedTab.classList.remove("d-none");
                        currentToggle.classList.add("skjema-admin__tabs__tab--active");
                    }else{
                        const notSelectedTab = document.querySelector(`[data-tab-id='${id}'][data-jscomponent='tabPanel']`);
                        notSelectedTab.classList.add("d-none");

                        arrOfToggles
                            .filter(elm => elm.getAttribute("data-tab-id") === id)[0]
                            .classList.remove("skjema-admin__tabs__tab--active");
                    }
                });
            });
        };

        arrOfToggles.forEach(addToggleListener);
    }
}