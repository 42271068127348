import {ID} from "./constants";

export const createMenuSection = (data:any, edocUrl="#") => {
    const arrayOfItems = data.Group.Item;

    const transformCustomUrl = (url:string) => {
        if(url.indexOf("edoc:AllCases") !== -1){
            return edocUrl + "#saksbehandler/allesaker";

        }else if(url.indexOf("edoc:MyCases") !== -1){
            return edocUrl + "#saksbehandler/minesaker";

        }else if(url.indexOf("edoc:UnassignedCases") !== -1){
            return edocUrl + "#saksbehandler/ufordeltesaker";

        }

        return url;
    };

    const links = (items:[]) => items.map(link => `
        <li class="skjema-admin__side-menu__item">
            <a  class="skjema-admin__side-menu__anchor" 
                data-js="${ID.MENU_ANCHOR}"
                href="${transformCustomUrl(link['@Href'])}">
                ${link['@Label']}
            </a>
        </li>
    `);

    return `
        <button 
            class="skjema-admin__side-menu__toggle-button" 
            data-js="${ID.TOGGLE_SIDE_MENU}"
            type="button">
            ${data['@Label']}
        </button>
        
        <ul class="skjema-admin__side-menu__list" data-js="${ID.MENU_LIST}">
            ${links(arrayOfItems).join("")}
        </ul>
        
    `;
};