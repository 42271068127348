export default function(jsComponent:string){
    const headers = Array.from(document.querySelectorAll(jsComponent));

    headers.forEach(header => {
        const text = <HTMLInputElement>header.nextElementSibling;
        const button = <HTMLButtonElement>text.nextElementSibling;

        if(button){
            button.addEventListener("click", () => {
                header.classList.toggle("d-none");
                text.classList.toggle("d-none");
            });
        }

        if(text){
            text.addEventListener("keyup", () => {
                header.innerHTML = text.value;
            });
        }
    });
}