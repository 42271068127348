// @ts-nocheck
export const syncRowHeight = (table:HTMLTableElement) => {
    const rows = Array.from(table.rows);
    rows.forEach(row => {
        const fakeTds = Array.from(row.querySelectorAll(".skjema-admin__table__list-td"));

        const highestFakeTd = fakeTds.reduce((acc:number, fakeTd:HTMLElement) => {
            const height = window.getComputedStyle(fakeTd).height.replace(/[^0-9.]/g,"");
            if(parseInt(height) > acc){
                acc = parseInt(height);
            }
            return acc;
        },0);

        fakeTds.forEach(fakeTd => (<HTMLElement>fakeTd).style.minHeight = highestFakeTd + "px");
    })
};

export default function(tableSelector:string, counterSelectors:string){
    const table = <HTMLTableElement>document.querySelector(tableSelector);
    if (table){
        console.warn("TableCheckCounter: Table not found, please check if id is correct");
    }

    let categories:Element[]=[];
    if(table){
        categories = Array.from(table.querySelectorAll("[data-belongsToCategory]"));
    }
    const counters = document.querySelectorAll(counterSelectors);

    const countChecked = () => {
        const arrOfCheckBoxes = Array.from(table.querySelectorAll("[type='checkbox']"));
        return arrOfCheckBoxes.reduce((acc:number, check:HTMLInputElement) => {
            if((<HTMLInputElement>check).checked){
                acc+=1;
                return acc
            }
            return acc;
        }, 0);
    };

    const countCheckedInCategory = (row:HTMLTableRowElement) => {
        const currentCategory = row.getAttribute("data-belongsToCategory");
        const categoryCounter = table.querySelector(`[data-counterForCategory='${currentCategory}']`);

        const checkboxesInCurrentCategory = Array.from(table.querySelectorAll(`[data-belongsToCategory='${currentCategory}'] [type='checkbox']`));
        const rowChecked = checkboxesInCurrentCategory.reduce((acc:number, checkbox:HTMLInputElement) => {
            if(checkbox.checked){
                acc += 1;
                return acc;
            }
            return acc;
        },0);

        categoryCounter.innerHTML = rowChecked > 0 ? rowChecked + " valgt" : "";
    };

    if(table){
        counters.forEach(counter => counter.innerHTML = countChecked() + " skjema er valgt.");
        categories.forEach((row:HTMLTableRowElement) => {
            countCheckedInCategory(row);
        });

        table.addEventListener("change", (event) => {
            counters.forEach(counter => counter.innerHTML = countChecked() + " skjema er valgt.");
            const row = (<HTMLElement>event.target).closest("tr");
            countCheckedInCategory(row);
        });
    }
}