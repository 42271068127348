// @ts-nocheck
import {ID, CLASS} from "./constants";
import {createMenuSection} from "./ui";

// @ts-ignore
import fetch from 'isomorphic-fetch';
import {
    applyLastState,
    applyAnchorState,
    saveLastState,
    toggleMenu,
    expandCollapse
} from "./features";

export default async function({
    targetElement,
    url,
    edocUrl,
    sideMenuContainer,
    mainPageContainer,
    sideMenuExpandButton}:{
    targetElement:HTMLElement,
    url:string,
    edocUrl:string,
    sideMenuContainer:HTMLElement,
    mainPageContainer:HTMLElement,
    sideMenuExpandButton:HTMLButtonElement
}){

    interface SideMenuUrl {
        "@Label":string,
        "@Href":string
    }

    interface SideMenuItem {
        "@Label":string,
        Group : {
            Item : SideMenuUrl[]
        }
    }

    interface SideMenu {
        Menu : {
            Group :{
                Item :SideMenuItem[]
            }
        }
    }

    const menuDataResponse = await fetch(url,{
        method:"get",
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
    });

    let result:SideMenu;

    try{
        result = await menuDataResponse.json();
    }catch (e){
        result = null;
    }

    if(result && result.Menu && result.Menu.Group){
        const {Item} = result.Menu.Group;

        const sideMenuHtml:string = Item.reduce((html:string, menuData:any) => {
            html+=createMenuSection(menuData,edocUrl);
            return html;
        },"");

        if(targetElement){
            targetElement.addEventListener("click", event => {
                const target = (event.target as HTMLElement);

                switch (target.getAttribute("data-js")) {
                    case ID.TOGGLE_SIDE_MENU:
                        toggleMenu(target,CLASS.ACTIVE_TOGGLE);
                        break;
                    case ID.MENU_ANCHOR:
                        applyAnchorState(targetElement,target);
                        saveLastState(target.getAttribute("href"));
                        location.href = target.getAttribute("href");
                        break;
                }
            });

            targetElement.innerHTML = sideMenuHtml;
            applyLastState(targetElement);
        }

        if(sideMenuExpandButton && sideMenuContainer){

            sideMenuContainer.style.borderRight="1px solid #b3b3b3";
            sideMenuContainer.style.maxWidth="387px";
            sideMenuContainer.style.transition="max-width 0.3s, width 0.3s";

            sideMenuExpandButton.addEventListener("click", () => {
                expandCollapse(
                    targetElement,
                    sideMenuContainer,
                    mainPageContainer,
                    sideMenuExpandButton);
            });
        }else{
            console.warn("Missing side menu expand button or container")
        }

        if(sideMenuExpandButton){
            document.addEventListener("scroll", () => {
                sideMenuExpandButton.style.top=pageYOffset+36+"px";
            });
        }
    }else{
        if(sideMenuExpandButton){
            sideMenuExpandButton.style.display = "none";
        }
    }
}
